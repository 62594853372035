<template>

    <div class="sports-links" style="width: 40%">
        <router-link tag="a" to="/user_info" class="link" active-class="active_sports_link">회원정보</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/myitems" class="link" active-class="active_sports_link">내아이템</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/quota" class="link" active-class="active_sports_link">손실한도설정</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/modifyuserinfo" class="link" active-class="active_sports_link">내정보수정</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/cancellation" class="link" active-class="active_sports_link">회원탈퇴</router-link>

    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_DISPLAY_POSTION} from "../../store/mutation-types";

    export default {
        name: "UserInfoLinksComp",
        props: {
        },
        data() {
            return {
                sportsConst,
            }
        },
        methods: {

        },
    }
</script>

<style scoped>

</style>