<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <user-info-links-comp></user-info-links-comp>
                <div class="board_list">
                    <div class="logo_panel" style="padding: 20px">
                        <div class="logo">
                            <img src="../../assets/images/logo/oneplay/oneplay_logo.png">
                        </div>
                        <div class="ment line mt10">
                            <span style="color: #079e39">원플레이</span>에 오신것을 환영합니다.
                        </div>
                        <div class="ment mt10" style="font-size: 12px;color: #c4c4c3">
                            <p>이용시 손실한도 및 로그인 제한을 설정 하실수 있습니다</p>
                        </div>

                        <h4 class="pay_title">손실한도 설정 </h4>
                        <div class="select_cash_buttons_panel">
                            <div class="quota_content">
                                <p>-게임 이용중 설정된 손실 한도를 초과하면 당일 게임이용이 제한됩니다</p>
                                <p>-손실한도는 매일 00:00시기준으로 초기화 됩니다 </p>
                                <p>-손실한도는 1일 1회까지 변경 가능합니다</p>
                                <p>-손실한도 금액은 베팅시 합산금액으로 적용됩니다</p>
                            </div>
                            <div>
                                <button class="btn03" @click="setQuotaCash(100000,1)"
                                        :class="{'active_coin_button':activeCoinButton === 1}">10만원
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="setQuotaCash(200000,2)"
                                        :class="{'active_coin_button':activeCoinButton === 2}">20만원
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="setQuotaCash(300000,3)"
                                        :class="{'active_coin_button':activeCoinButton === 3}">30만원
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="setQuotaCash(400000,4)"
                                        :class="{'active_coin_button':activeCoinButton === 4}">40만원
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="setQuotaCash(500000,5)"
                                        :class="{'active_coin_button':activeCoinButton === 5}">50만원
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="setQuotaCash(0,6)"
                                        :class="{'active_coin_button':activeCoinButton === 6}">제한없음
                                </button>
                            </div>
                            <button class="btn02" style="width: 50%;padding: 20px 0;margin-top: 20px"
                                    @click="doSetQuota()">변경하기
                            </button>
                        </div>

                        <h4 class="pay_title">이용기간 제한 설정</h4>
                        <div class="select_cash_buttons_panel">
                            <div class="quota_content">
                                <p>-회원 스스로 설정한 일정 기간동안 로그인을 제한하는 기능입니다</p>
                                <p>-기간이 만료되기 전에는 중도 해제가 불가능하므로 설정 실수로 인하여 불이익을 받지 않도록 주의해주시기 바랍니다 </p>
                            </div>
                            <div>
                                <button class="btn03" @click="setDenyMonth(1,1)"
                                        :class="{'active_coin_button':activeCoinButton2 === 1}">1개월
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="setDenyMonth(2,2)"
                                        :class="{'active_coin_button':activeCoinButton2 === 2}">2개월
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="setDenyMonth(3,3)"
                                        :class="{'active_coin_button':activeCoinButton2 === 3}">3개월
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="setDenyMonth(6,4)"
                                        :class="{'active_coin_button':activeCoinButton2 === 4}">6개월
                                </button>
                            </div>
                            <div>
                                <button class="btn03" @click="setDenyMonth(12,5)"
                                        :class="{'active_coin_button':activeCoinButton2 === 5}">12개월
                                </button>
                            </div>
                            <button class="btn02" style="width: 50%;padding: 20px 0;margin-top: 20px"
                                    @click="dodenylogin()">설정하기
                            </button>
                        </div>


                    </div>

                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>
    </div>

</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import {
        deleteApply,
        getApplyCashList,
        logout,
        rechargeCash,
        setDenyLogin,
        setQuote
    } from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {RECEIVE_SPORTS_CART_ITEM_DEL_ALL} from "../../store/mutation-types";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import ShopLinksComp from "../../components/links/ShopLinksComp";
    import UserInfoLinksComp from "../../components/links/UserInfoLinksComp";

    export default {
        name: "quota",
        components: {
            UserInfoLinksComp,
            ShopLinksComp,
            SportsLeftBarComp,
            RightBarBannerComp, TopbarComp, FootComp, LeftBarComp, RightBarComp, SubTitle
        },
        data() {
            return {
                sportsConst,
                applyCash: {
                    amount: null
                },
                search: {applyType: sportsConst.APPLY_TYPE_RECHARGE},
                rechargeList: [],
                isSampleSite: false,
                activePay: 0,
                activeCoinButton: 0,
                activeCoinButton2: 0,
                quotaCash: -1,
                denyMonth: -1,
                allowBuy: false,
                nowmonth: this.$moment().format('YYYY년 MM월')
            }
        },
        methods: {
            changePayType(type) {
                if (type > 1) {
                    this.$swal({
                        title: '서비스 준비중입니다',
                        type: 'info',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.activePay = type;
            },
            setQuotaCash(cash, ab) {
                this.activeCoinButton = ab;
                this.quotaCash = cash;
            },
            setDenyMonth(month, ab) {
                this.activeCoinButton2 = ab;
                this.denyMonth = month;
            },
            doSetQuota() {
                if(this.quotaCash < 0){
                    this.$swal({
                        title: '손실한도를 선택하세요',
                        type: 'warning',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }

                this.$swal({
                    title: '손실한도 ' +this.quotaCash + '원 설정하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {

                        setQuote(this.quotaCash).then(res=>{
                            if (res.data.success) {
                                this.$swal({
                                    title: '손실한도 설정이 완료되였습니다',
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })

                    }
                })
            },
            dodenylogin() {
                if(this.denyMonth < 0){
                    this.$swal({
                        title: '제한월수를 선택하세요',
                        type: 'warning',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }

                this.$swal({
                    title: '로그인 제한을  ' + this.denyMonth + '개월 설정하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {

                        setDenyLogin(this.denyMonth).then(res=>{
                            if (res.data.success) {
                                this.$swal({
                                    title: '로그인제한 설정이 완료되였습니다',
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                logout();
                                this.$store.state.isLogin=false
                                window.sessionStorage.clear();
                                this.$router.replace("/login")
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })

                    }
                })
            },

        },
        created() {
            if (!this.$store.state.isLogin) {
                this.$swal({
                    title: '로그인이 필요한 서비스 입니다.',
                    type: 'warning',
                    showCancelButton: false,
                    showConfirmButton: true
                })
                this.$router.push('/login')
                return false;
            }
        },
        mounted() {

        }
    }
</script>

<style scoped>
    @import url("../../assets/css/reex.css");
    .quota_content {
        font-size: 10px;
        text-align: left;
        width: 100%!important;
        box-sizing: border-box;
        padding-left: 10px;
        color: #c4c4c3
    }
    @media screen and (max-width: 1024px) {
        .quota_content{
            width: 100%!important;
        }
    }
</style>